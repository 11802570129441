<template>
  <transition name="modal-container">
    <div
      v-on-clickaway="closeModal"
      class="modal-container"
    >
      <div class="align-inners">
        <div class="flex flex-col border-r px-5 pt-5 pb-2 flex-1">
          <div class="hook-color rounded-md p-2 items-center w-11 h-11">
            <img
              src="../../../assets/icons/hook.svg"
              alt="hook"
              class="h-7 w-7"
            >
          </div>
          <div
            v-if="!showGeneratedHooks"
            class="flex flex-row gap-2 mt-5 items-center"
          >
            <BaseText
              type="heading"
              size="sm"
              class="text-text-loud text-base"
            >
              Hook Generator
            </BaseText>
            <BaseText
              type="label"
              size="xs"
              class="hook-color rounded-md text-white py-0.5 px-1"
            >
              NEW!
            </BaseText>
          </div>
          <BaseText
            v-else
            type="heading"
            size="sm"
            class="text-text-loud text-base mt-5"
          >
            Your New Hooks Have Arrived!
          </BaseText>
          <BaseText
            v-if="!showGeneratedHooks"
            type="body"
            size="sm"
            class="mt-1 text-text-normal"
          >
            Automatically generate customized hooks using your pinned hooks from {{ advertisements[0].name }} as inspiration.
          </Basetext>
          <BaseText
            v-else
            type="body"
            size="sm"
            class="mt-1 text-text-normal"
          >
            Check out the new hooks that have been generated for {{ product.name }} using {{ advertisements[0].name }} as inspiration.
          </Basetext>
        </div>
        <div
          v-if="!showGeneratedHooks"
          class="flex flex-col"
        >
          <BaseText
            type="label"
            size="sm"
            class="flex flex-col border-t border-r p-5 text-text-muted"
          >
            Inspirational Input
          </BaseText>
          <div class="flex flex-row items-center justify-between border-r p-5">
            <div class="flex flex-row">
              <BaseText
                type="label"
                size="sm"
              >
                {{ advertisements[0].name }}
              </BaseText>
            </div>
            <div
              class="flex flex-row p-1.5 items-center rounded-md relative source-box source-box-shadow"
              @click="toggleSourceSelector"
            >
              <BaseText
                type="label"
                size="sm"
              >
                Source
              </BaseText>
              <ChevronIcon
                class="transform transition-transform duration-200"
                :style="sourceSelectorOpen ? 'transform: scale(1, -1)' : ''"
              />
              <transition name="source-dropdown">
                <div
                  v-if="sourceSelectorOpen"
                  v-on-clickaway="() => { sourceSelectorOpen = false }"
                  class="generator-source-dropdown source-box-shadow"
                >
                  <div
                    v-for="option in sourceOptions"
                    :key="option"
                    class="source-row"
                    @click.stop="selectSource(option)"
                  >
                    <div>
                      <BaseText
                        type="label"
                        size="sm"
                      >
                        {{ option }}
                      </BaseText>
                    </div>
                    <CheckmarkIcon
                      v-if="selectedSource === option"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div
          v-else
          class="w-full p-5 text-center border-r"
        >
          <div
            class="csv-export"
            @click="exportHooksToCSV"
          >
            <ExportCSVIcon />
            <BaseText>
              Export (CSV)
            </BaseText>
          </div>
        </div>
      </div>
      <div
        v-if="!showGeneratedHooks"
        class="align-inners flex flex-col p-5 text-text-muted text-base gap-5"
      >
        <div class="flex flex-col">
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Context
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal"
          >
            Enter details that describe your brand.
          </BaseText>
        </div>
        <div
          class="flex flex-col gap-3 flex-1"
        >
          <div>
            <BaseText
              type="label"
              size="sm"
              class="mb-1 text-text-muted"
            >
              Brand name
            </BaseText>
            <input
              v-model="product.name"
              type="text"
              class="input text-text-subdued"
              placeholder="ex: Nike"
            >
          </div>

          <div>
            <BaseText
              type="label"
              size="sm"
              class="mb-1 text-text-muted"
            >
              Product Description
            </BaseText>
            <textarea
              v-model="product.description"
              type="text"
              class="input text-text-subdued h-24 overflow-scroll desc-input"
              placeholder="ex: Shoes for athletes who want to feel great and perform at a high level."
            />
          </div>
          <div>
            <BaseText
              type="label"
              size="sm"
              class="mb-1 text-text-muted"
            >
              Value Propositions
            </BaseText>

            <input
              v-model="product.valuePropositions"
              type="text"
              class="input text-text-subdued"
              placeholder="ex: Comfort, Style, Performance"
            >
          </div>
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal"
          >
            Separate items with commas.
          </BaseText>
          <BaseText
            type="body"
            size="md"
            class="text-red-500"
          >
            {{ showFillFieldsAlert ? fillFieldsAlert : '' }}
          </BaseText>
        </div>

        <div
          class="hook-color px-3 py-1.5 rounded-md text-center text-white generate-hooks-button"
          @click="generateHooksFromPinned(product)"
        >
          <BaseText
            type="label"
            size="sm"
          >
            Generate Hooks
          </BaseText>
        </div>
      </div>
      <div
        v-if="showGeneratedHooks"
        class="align-inners flex flex-col p-5 text-text-muted text-base gap-2 overflow-scroll"
      >
        <div
          v-for="(hook, index) in generatedManualHooks"
          :key="hook + index"
          class="rounded-md p-3 bg-background-normal"
        >
          <BaseText
            type="body"
            size="sm"
          >
            {{ hook }}
          </BaseText>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { mixin as clickaway } from 'vue-clickaway2'
import smoothReflow from 'vue-smooth-reflow'
import EventBus from '../../../plugins/BaseNotifications/EventBus'
import ForeplayAPI from '@/api/foreplayServer'
import { getHook } from '../../../utils/adFunctions'

// Icons
import ChevronIcon from '../../../components/globals/Icons/ChevronIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
import ExportCSVIcon from '../../globals/Icons/ExportCSVIcon.vue'

export default {
  name: 'ManualHooksGeneratorModal',
  components: {
    ChevronIcon,
    CheckmarkIcon,
    ExportCSVIcon
  },
  mixins: [smoothReflow, clickaway],
  props: {
    brand: {
      type: Object,
      required: true
    },
    pinnedAds: {
      type: Array,
      required: true
    },
    advertisements: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sourceOptions: [
        'Pinned Hooks - Rigid',
        'Pinned Hooks - Creative',
        'Top Performing Hooks - Rigid',
        'Top Performing Hooks - Creative'
      ],
      product: {
        name: '',
        description: '',
        valuePropositions: ''
      },
      sourceSelectorOpen: false,
      selectedSource: null,
      isMounted: false,
      pinnedHooks: [],
      topPerformingHooks: [],
      generatedManualHooks: [],
      fillFieldsAlert: '',
      showFillFieldsAlert: false,
      showGeneratedHooks: false,
      promptType: null
    }
  },
  computed: {
    // Your computed properties go here
  },
  async mounted () {
    EventBus.$on('source-change', (alert) => this.enqueueAlert(alert))
    this.$smoothReflow({
      el: '.generator-source-dropdown',
      property: ['width'],
      transition: 'width 0.2s ease-in-out'
    })

    await this.getHooksFromPinned(this.pinnedAds)
    await this.getTopPerformingHooks()
    // console.log('top performing hooks', this.topPerformingHooks)
    // console.log('pinned hooks', this.pinnedHooks)
  },
  methods: {
    toggleSourceSelector () {
      this.sourceSelectorOpen = !this.sourceSelectorOpen
    },
    selectSource (option) {
      this.selectedSource = option
      if (option === 'Pinned Hooks - Rigid' || option === 'Top Performing Hooks - Rigid') {
        this.promptType = 'Rigid'
      } else if (option === 'Pinned Hooks - Creative' || option === 'Top Performing Hooks - Creative') {
        this.promptType = 'Creative'
      } else {
        this.promptType = null
      }
    //   console.log(this.selectedSource)
    //   console.log(this.sourceSelectorOpen)
    },
    async getTopPerformingHooks () {
      try {
        const { results } = await ForeplayAPI.Ads.getSpyderAds(null, {
          sort: 'longest',
          transciptionOnly: true,
          orFilters: {
            liveStatus: [{ value: 'live' }],
            brands: [{ brandId: this.$route.params.id }]
          },
          size: 100
        })
        // TODO: WE SHOULD NOT BE FILTERING HERE, BACKEND SHOULD RETURN ONLY ADS WITH TRANSCRIPTIONS
        const resultHooks = results.filter(ad => ad.timestampedTranscription.length > 0).map(ad => getHook(ad))
        console.log('RESULT HOOKS', resultHooks)
        const uniqueHooks = Array.from(new Set(resultHooks))
        console.log('UNIQUE HOOKS', uniqueHooks)

        this.topPerformingHooks = uniqueHooks
        // console.log('TOP PERFORMING HOOKS', this.topPerformingHooks)
      } catch (error) {
        console.log('error fetching hooks')
        console.error(error)
      }
    },
    async generateHooksFromPinned (product) {
      if (this.product.name === '' || this.product.description === '' || this.product.valuePropositions === '' || this.promptType === null) {
        if (this.product.name === '' || this.product.description === '' || this.product.valuePropositions === '') {
          this.fillFieldsAlert = 'Please fill out all fields and select a source.'
        }
        if (this.promptType === null) {
          this.fillFieldsAlert = 'Please select a source.'
        }
        this.showFillFieldsAlert = true
        return
      } else {
        this.showFillFieldsAlert = false
      }
      let hooks
      if (this.selectedSource === 'Pinned Hooks - Rigid' || this.selectedSource === 'Pinned Hooks - Creative') {
        hooks = this.pinnedHooks
      } else if (this.selectedSource === 'Top Performing Hooks -Creative' || this.selectedSource === 'Top Performing Hooks - Rigid') {
        hooks = this.topPerformingHooks
      }

    //   console.log('PRODUCT', product)
    //     console.log('HOOKS IN PINNED', hooks)
    //     console.log('PROMPT TYPE', this.promptType)

      const result = await ForeplayAPI.Brands.generateHooksFromPinned(product, hooks, this.promptType)
      this.showGeneratedHooks = true
      this.generatedManualHooks = result
    },
    getHooksFromPinned (pinnedAds) {
      this.pinnedHooks = pinnedAds.map(ad => getHook(ad))
    },
    exportHooksToCSV () {
      if (!this.generatedManualHooks.length) return
      let csvContent = 'Hooks\n'
      this.generatedManualHooks.forEach((hook) => {
        const row = `"${hook.replace(/"/g, '""')}"\n`
        csvContent += row
      })
      const sanitizedFileName = this.advertisements[0].name.replace(/[^a-z0-9]/gi, '_').toLowerCase()
      const blob = new Blob([csvContent.trim()], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${sanitizedFileName}-style-${this.product.name}-hooks.csv`
      link.click()
    },
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>

  <style scoped>
  .modal-container {
    width: 600px;
    height: 500px;
    position: fixed;
    display: flex;
    flex-direction: row;
    top: 15vh;
    left: 500px;
    z-index: 99991; /*  greater than filterTopBar */
    background-color: white;
    color: black;
    border-radius: 12px;
    box-shadow: 0px 24px 32px -12px rgba(53, 57, 75, 0.24),
              0px 1px 2px 0px rgba(162, 172, 186, 0.16),
              0px 0px 0px 1px rgba(0, 56, 108, 0.08);
    box-shadow: 0px 24px 32px -12px color(display-p3 0.21 0.2233 0.29 / 0.24),
              0px 1px 2px 0px color(display-p3 0.6433 0.6728 0.7254 / 0.16),
              0px 0px 0px 1px color(display-p3 0.0696 0.2157 0.4104 / 0.08);
  }
  .modal-container-enter-active, .modal-container-leave-active {
    transition: opacity 0.2s;
  }
  .modal-container-enter, .modal-container-leave-to {
  opacity: 0;
}
  .align-inners {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .align-inners::-webkit-scrollbar {
  display: none;
  }

    .align-inners {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
  .hook-color {
    background-color: #ED615A;
  }
  .input {
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
    width: 250px;
  }
  .source-box:hover {
    cursor: pointer;
  }
  .source-box-shadow {
    box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
    box-shadow: 0px 1px 2px 0px color(display-p3 0.0696 0.2157 0.4104 / 0.08), 0px 0px 0px 1px color(display-p3 0.0696 0.2157 0.4104 / 0.08);
  }
  .generator-source-dropdown {
    position: absolute;
    right: 0;
    top: -155px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 10px;
    padding: 4px;
    background-color: white;
    z-index: 9999;
    color: black;
  }
  .generator-source-dropdown:hover {
    cursor: pointer;
  }
  .source-dropdown-enter-active, .source-dropdown-leave-active {
    transition: opacity 0.2s;
  }
  .source-dropdown-enter, .source-dropdown-leave-to {
  opacity: 0;
}
.source-row {
    padding: 6px 8px 6px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: nowrap;
    white-space: nowrap;
    border-radius: 10px;
}
.source-row:hover {
    background-color: #F3F4F6;
}
.desc-input {
    resize: none;
}
.generate-hooks-button {
    cursor: pointer;
}
.csv-export {
    display: flex;
    flex-direction: row;
    color: white;
    background-color: #ED615A;
    border-radius: 10px;
    padding: 6px 8px 6px 8px;
    gap: 6px;
    align-items: center;
    justify-content: center;
}
.csv-export:hover {
    cursor: pointer;
    background-color: #f4544b;
}
.scale-x-1 {
  transform: scaleX(1);
}

.-scale-x-1 {
  transform: scaleX(-1);
}
  </style>
