<template>
  <button class="group flex items-center gap-3 py-2 pl-2 pr-3 rounded-md transition-colors"
  :class="getColorClasses('bg-transparent', 'bg-neutral-alpha-25', true)"
  @click="handleBoardSelected" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
    <div v-if="isAnimating || (isSelected && !hasBeenClicked)" class="w-5 h-5">
      <BoardSelectableIcon :isSelected="isSelected" :hasBeenClicked="hasBeenClicked" @deselectAnimEnded="handleDeselectAnimEnded" />
    </div>
    <BoardIcon v-else :ref="`board-icon-${board.id}`" class="transition-colors"
    :class="getColorClasses('text-neutral-malpha-650', 'text-white')" />
    <BaseText type="body" size="sm" class="transition-colors"
    :class="getColorClasses('text-neutral-malpha-800', 'text-white')">
      {{ board.name.slice(1) }}
    </BaseText>
    <BaseText v-if="board.adCount || board.adCount === 0" type="body" size="xs" 
    class="ml-auto transition-colors"
    :class="getColorClasses('text-neutral-malpha-600', 'text-white')">
      {{ board.adCount.doc_count }} {{ board.adCount.doc_count === 1 ? 'ad' : 'ads' }}
    </BaseText>
  </button>
</template>

<script>
import BoardIcon from '../../globals/Icons/BoardIcon.vue'
import BoardSelectableIcon from '../../globals/Icons/BoardSelectableIcon.vue'

export default {
  name: 'SaveAdBoardRow',
  components: {
    BoardIcon,
    BoardSelectableIcon
  },
  props: {
    board: {
      // Boards that get passed to this component should be extended with the adCount property
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isCurrentNav: {
      type: Boolean,
      default: false
    },
    keyboardNavActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isAnimating: false,
      isHovered: false,
      hasBeenClicked: false
    }
  },
  methods: {
    handleBoardSelected () {
      this.$emit('selected')
      this.isAnimating = true
      this.hasBeenClicked = true
    },
    handleDeselectAnimEnded () {
      this.isAnimating = false
      // Since we are switching between an animated icon and a static icon, we need to force the 
      // static icon to animate its unhover animation when the anim completes while the icon is not hovered.
      if (!this.isHovered) {
        this.$nextTick(() => {
          const staticIcon = this.$refs[`board-icon-${this.board.id}`].$el
          staticIcon.classList.add('force-unhover-anim')
          setTimeout(() => {
            staticIcon.classList.remove('force-unhover-anim')
          }, 150)
        })
      }
    },
    getColorClasses (baseColorClass, hoverColorClass, isParent = false) {
      return this.isCurrentNav
        ? hoverColorClass
        : !this.keyboardNavActive
          ? `${baseColorClass} ${isParent ? 'hover' : 'group-hover'}:${hoverColorClass}`
          : baseColorClass
    }
  }
}
</script>

<style scoped>
.force-unhover-anim {
  animation: colorFadeOut 150ms ease-in-out;
}
@keyframes colorFadeOut {
  from { color: white }
  to { color: #B3B4B7 }
}
</style>