<template>
  <div class="filter-search-container relative px-1 transition-colors ease-in-out"
  :style="{maxWidth: isMultiSelect ? '200px' : '140px'}" 
  :class="isExpanded ? 'expanded rounded-t-lg' : 'rounded-lg'" 
  v-on-clickaway="() => { if (!isMultiSelect) isExpanded = false}">
    <div id="filterSearch" class="flex items-center gap-2 w-full px-2 py-1" @click="barClicked"
    :class="{'cursor-pointer z-50': !isExpanded}">
      <img v-show="isExpanded" src="../../../assets/icons/search.svg" alt="" class="w-5 h-5" />
      <div v-show="!isExpanded" class="w-5">
        <FilterIcon stroke="#808899" />
      </div>
      <input :ref="`filter-${filterType}-input`" v-model="searchQuery" :placeholder="placeholder"
      class="filter-search-input flex-grow min-w-0 bg-transparent" :class="{'cursor-pointer': !isExpanded}"
      autocomplete="off" type="text" spellcheck="false"
      @focus="() => { isExpanded = true; keyboardIndex = -1; $emit('focusCurr') }"
      @keydown.down.prevent="highlightNext"
      @keydown.up.prevent="highlightPrev"
      @keydown.enter.prevent="selectHighlighted"
      @keydown.right="focusNext"
      @keydown.left="focusPrev"
      @keydown.escape.prevent="collapseSearch"
      @keydown="($event) => { if ($event.key === 'Delete') { $event.preventDefault(); $emit('removeFilterType') }}"/>
    </div>
    <div :class="{'expanded' : isExpanded}"
    class="filter-search-dropdown-container absolute top-full left-0 right-0 overflow-hidden rounded-b-lg bg-white">
      <div class="filter-search-dropdown flex flex-col gap-0.5 py-1" :class="{'expanded' : isExpanded}">
        <div v-for="(item, index) in searchResults" :key="index" @click.stop="itemClicked(item)" @mouseenter="keyboardIndex = -1"
        class="flex items-center gap-2 px-2 mx-1 py-1.5 rounded-md transition-colors ease-in-out hover:bg-neutral-25 hover:z-50 cursor-pointer"
        :class="{'bg-neutral-25 z-50': keyboardIndex === index}" :ref="`${filterType}-item-${index}`">
          <!-- Icon rendering -->
          <TagIcon v-if="filterType === 'tag'" :stroke="item.color ? tagColors[item.color].stroke : tagColors['gray'].stroke"
          :fill="item.color ? tagColors[item.color].fill : tagColors['gray'].fill" />
          <img v-else-if="filterType === 'brand'" :src="item.icon" class="w-5 h-5 object-fill" style="border-radius: 4.5px" />
          <div v-else-if="filterType === 'status'" class="px-1.5">
            <div class="w-1.5 h-1.5 rounded-full" :style="{backgroundColor: item.value === 'live' ? '#00A879' : '#9DA3AE'}" />
          </div>
          <component v-else-if="filterType === 'platform'" :is="item.icon" />
          <component v-else :is="item.icon" stroke="#5E6678" />
          <!-- Item name -->
          <div class="w-full text-sm text-text-normal truncate">
            {{ item.name }}
          </div>
          <!-- Checkmark if selected -->
          <div v-if="isMultiSelect && isItemSelected(item)" class="ml-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5.375 11.5469L8.5 14.125L14.625 5.875" stroke="#5E6678" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div v-if="searchResults.length === 0" class="px-2 mx-1 py-1.5 text-sm text-text-disabled">
          {{ isMultiSelect || searchQuery.length > 0 ? 'No results found' : 'All filters applied' }}
        </div>
        <!-- Inform user that live status only works for FB ad library -->
        <div v-if="filterType === 'status'" 
        class="flex gap-2 items-center px-3 pt-2 mt-0.5 pb-1 border-t border-border-normal cursor-default">
          <FacebookPlatformIcon />
          <div class="text-sm text-text-normal">
            Ad Library Ads Only
          </div>
        </div>
      </div>
      <div v-if="searchResults.length > 8" class="dropdown-fade-overlay pointer-events-none" />
    </div>
  </div>
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway2'
  import FilterIcon from '../../globals/Icons/FilterIcons/FilterIcon.vue'
  import TagIcon from '../../globals/Icons/TagIcon.vue'
  import FacebookPlatformIcon from '../../globals/Icons/FilterIcons/Platforms/FacebookPlatformIcon.vue'
  import TAG_COLORS from '../../../utils/TagColors'

  export default {
    name: 'FilterSearch',
    mixins: [clickaway],
    components: {
      FilterIcon,
      TagIcon,
      FacebookPlatformIcon
    },
    props: {
      filterType: {
        type: String,
        default: ''
      },
      isMultiSelect: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        required: true
      },
      placeholder: {
        type: String,
        default: 'Add a Filter'
      },
      selections: {
        type: Array,
        default: () => []
      },
      isFocused: {
        type: Boolean,
        default: false
      },
      hasNextItem: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      searchResults () {
        let searchItems = this.items.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
        
        // Sort by the closeness of the match (closer = lower index)
        searchItems.sort((a, b) => {
          const ratioA = this.searchQuery.length / a.name.length;
          const ratioB = this.searchQuery.length / b.name.length;

          return ratioB - ratioA;
        });

        return searchItems;
      }
    },
    watch: {
      isFocused (newVal) {
        if (!this.isMultiSelect && newVal) {
          this.isExpanded = true
          this.$refs[`filter-${this.filterType}-input`].focus()
        }
      }
    },
    mounted () {
      if (this.isMultiSelect) {
        this.$refs[`filter-${this.filterType}-input`].focus()
        this.isExpanded = true
      }
    },
    data () {
      return {
        searchQuery: '',
        isExpanded: false,
        keyboardIndex: -1,
        tagColors: TAG_COLORS
      }
    },
    methods: {
      itemClicked (item, wasKeypress = false) {
        item.action()
        if (!this.isMultiSelect) this.isExpanded = false

        // Since status only has two options, we close the dropdown on selection
        else if (this.filterType === 'status') {
          if (wasKeypress) this.$emit('focusPrev')
          else this.$emit('collapseSearch')
        }
      },
      isItemSelected (item) {
        return this.selections.some(selection => selection.name === item.name)
      },
      barClicked () {
        if (!this.isMultiSelect) this.$refs[`filter-${this.filterType}-input`].focus()
      },

      // ========== KEYBOARD CONTROLS ==========
      highlightNext () {
        if (this.keyboardIndex < this.searchResults.length - 1) {
          this.keyboardIndex++
          this.scrollToItem(`${this.filterType}-item-${this.keyboardIndex}`)
        }
      },
      highlightPrev () {
        if (this.keyboardIndex > -1) {
          this.keyboardIndex--
          if (this.keyboardIndex !== -1) this.scrollToItem(`${this.filterType}-item-${this.keyboardIndex}`)
        }
      },
      selectHighlighted () {
        if (this.keyboardIndex > -1) {
          this.itemClicked(this.searchResults[this.keyboardIndex], true)
        }
      },
      scrollToItem (ref) {
        this.$nextTick(() => {
          const elm = this.$refs[ref][0]
          if (elm) elm.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        })
      },
      focusNext () {
        if (this.searchQuery === '' && this.hasNextItem) {
          this.$refs[`filter-${this.filterType}-input`].blur()
          this.$emit('focusNext')
          if (!this.isMultiSelect) this.isExpanded = false
        }
      },
      focusPrev () {
        if (this.searchQuery === '') {
          this.$emit('focusPrev')
          if (this.isMultiSelect) this.$refs[`filter-${this.filterType}-input`].blur()
        }
      },
      collapseSearch () {
        if (!this.isMultiSelect) {
          this.isExpanded = false
          this.$refs[`filter-${this.filterType}-input`].blur()
        } 
        else this.$emit('collapseSearch')
      }
    }
  }
</script>

<style scoped>
  .filter-search-container {
    transition: box-shadow 150ms ease-in-out, padding-top 100ms ease-in-out, padding-bottom 100ms ease-in-out, background-color 150ms ease-in-out;
    box-shadow: none;
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: transparent;
  }
  .filter-search-container:hover {
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px #FFF;
    background-color: white;
  }
  .filter-search-container.expanded {
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px #FFF;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background-color: white;
  }
  .filter-search-dropdown-container {
    opacity: 0;
    z-index: 100;
  }
  .filter-search-dropdown-container.expanded {
    opacity: 1;
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px #FFF;
  }
  .filter-search-dropdown {
    overflow-y: scroll;
    max-height: 0px;
    scrollbar-width: none;
  }
  .filter-search-dropdown.expanded {
    transition: max-height 150ms ease-out;
    max-height: 327px;
  }
  .dropdown-fade-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16px;
    z-index: 40;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffffb9 100%);
  }
  .filter-search-input {
    /* Reset default styles */
    -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
    -moz-appearance: none;     /* Remove default styling in Firefox */
    appearance: none;          /* Remove default styling in modern browsers */
    padding: 0;
    margin: 0;
    border: none;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    outline: none;
  }
  .filter-search-input:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  .filter-search-input::placeholder {
    transition: color 150ms ease-in-out;
    color: #5E6678;
  }
  .filter-search-input:hover::placeholder {
    color: black;
  }
  .filter-search-input:focus::placeholder {
    color: #A4ACB9;
  }
</style>