<template>
  <div class="w-full h-screen flex flex-col relative"
  >
  <div class="hooks-generator-button">
  <div class="inner-hooks-generator-button"
  @click="toggleOpenManualHooksGenerator"
  >
    <img src="../../../assets/icons/hook.svg" alt="hook" class="h-6 w-6"/>
    <BaseText type="label" size="sm" class="text-white ml-2 mr-6">
      Hook Generator
    </BaseText>
    <BaseText type="label" size="sm" class="text-white hover-animation">
      NEW!
    </BaseText>
  </div>
  </div>
    <ManualHooksGeneratorModal
      v-if="manualHooksGeneratorOpen"
      :pinnedAds="pinnedAds"
      :advertisements="advertisements"
      @close="toggleOpenManualHooksGenerator"
      />
    <div class="w-full flex flex-col py-2.5 gap-2.5 mt-3 border-t border-b border-border-normal">
      <div
      class=" flex flex-col mx-2">
        <button class="flex flex-row justify-between pr-3 items-center" 
        @click="() => { if (pinnedAds.length > 0) showOptions = !showOptions}"
        :class="{'cursor-default': pinnedAds.length === 0}">
          <div class="flex flex-row items-center">
            <div class="flex flex-row gap-3 px-4 py-1 items-center">
              <img src="../../../assets/icons/unpinned.svg" />
              <BaseText type="label" size="sm" class="text-text-muted">
                Pinned Hooks
              </BaseText>
              <BaseText
                v-if="pinnedAds.length === 0"
                type="body"
                size="xs"
                class="text-neutral-500"
              >
                You don't have any hooks pinned.
              </BaseText>
            </div>
          </div>
          <div class="flex flex-row">
            <transition>
              <div v-if="showOptions && pinnedAds.length > 0">
                <div class="flex flex-row items-center gap-2">
                  <!-- <button class="flex flex-row py-1.5 px-2 gap-1.5 rounded-md transition-colors hover:bg-neutral-25"
                  @click.stop.prevent="toggleOpenManualHooksGenerator">
                  <img src="../../../assets/icons/hook.svg" alt="hook" class="h-5 w-5 bg-gray-500 bg-opacity-40 rounded-sm"/>
                    <BaseText type="label" size="sm" class="text-text-muted">
                      Generate Personalized Hooks
                    </BaseText>
                  </button> -->
                  <button class="flex flex-row py-1.5 px-2 gap-1.5 rounded-md transition-colors hover:bg-neutral-25"
                  @click.stop.prevent="exportCSV">
                    <ExportCSVIcon class="text-icon-normal" />
                    <BaseText type="label" size="sm" class="text-text-muted">
                      Export (CSV)
                    </BaseText>
                  </button>
                  <button class="flex flex-row py-1.5 px-2 gap-1.5 rounded-md transition-colors hover:bg-neutral-25"
                  @click.stop.prevent="copyAllPins">
                    <CopyIconStatic class="text-icon-normal" />
                    <BaseText type="label" size="sm" class="text-text-muted">
                      Copy All Pins (Text)
                    </BaseText>
                  </button>
                  <button class="flex flex-row py-1.5 px-2 gap-1.5 rounded-md transition-colors hover:bg-neutral-25"
                  @click.stop.prevent="clearPins">
                    <UnpinIcon class="text-icon-normal" />
                    <BaseText type="label" size="sm" class="text-text-muted">
                      Clear Pins
                    </BaseText>
                  </button>
                </div>
              </div>
            </transition>
            <transition>
              <div v-if="pinnedAds.length > 0"
              class="p-1.5 ml-2 rounded-md transition-colors hover:bg-neutral-25">
                <ChevronIcon class="text-icon-normal transform" style="transition: transform 150ms ease-in-out"
                :style="{ transform: showOptions ? 'scaleY(-1)' : 'none' }" /> 
              </div>
            </transition>
          </div>
        </button>
        <div class="flex flex-col gap-1.5 overflow-hidden px-1"
        style="transition: height 200ms ease-in-out" 
        :style="{height: showOptions && pinnedAds.length > 0 ? `${pinnedAds.length * 58 + 4}px` : '0px'}">
          <div v-for="(ad, index) in pinnedAds" :key="`pinned-hook-${index}`"
          :class="{'mt-2': index === 0, 'mb-1': index === pinnedAds.length - 1}"
          @click="attemptManualPreviewPlay = !attemptManualPreviewPlay">
            <AdvertisementHookRow  
              :ad="ad" 
              :ref="`hook-${ad.id}`"
              :isPinned="true"
              :pinnedAds.sync="pinnedAds"
              @unpinAd="unpinAd(ad)"
              @openDetailsDrawer="handleOpenAdvertisementDetailsDrawer(ad)"
              @thumbnailHovered="hoveredHookPreview = ad"
              @thumbnailUnhovered="hoveredHookPreview = null"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col gap-1.5 px-3 py-3"
      @click="attemptManualPreviewPlay = !attemptManualPreviewPlay"
    >
      <AdvertisementHookRow
        v-for="(ad, index) in filteredAdvertisements"
        :key="ad.id"
        :ad="ad"
        :ref="`hook-${ad.id}`"
        :isPinned="false"
        :pinnedAds.sync="pinnedAds"
        @pinAd="pinAd(ad)"
        @openDetailsDrawer="handleOpenAdvertisementDetailsDrawer(ad)"
        @thumbnailHovered="hoveredHookPreview = ad"
        @thumbnailUnhovered="hoveredHookPreview = null"
      />

      <infinite-loading
        v-if="filteredAdvertisements.length && !trialExpired"
        :identifier="infiniteId"
        @infinite="getMoreAds"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>

    <AdvertisementDetailsDrawer
      v-if="showAdvertisementDetailsDrawer && !trialExpired"
      :all-ads.sync="advertisements"
      :discovery="true"
      :advertisement="selectedAdvertisement"
      :shared-board="sharedBoard"
      :shared-brief="sharedBrief"
      :loading="loading"
      @getMoreAds="$emit('getMoreAds')"
      @close="closeModal"
      @save="$emit('reload')"
    />
    <transition>
      <BasePopupVideoPreview
        v-if="hoveredHookPreview"
        class="fixed transform -translate-y-1/2"
        :style="{
          top: `${hookPreviewPosition.y}px`,
          left: `${hookPreviewPosition.x}px`,
          zIndex: '40010'
        }"
        :videoSrc="hoveredHookPreview.video"
        :attemptManualPlay="attemptManualPreviewPlay"
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { getHook } from "../../../utils/adFunctions";
import AdvertisementDetailsDrawer from "../AdvertisementDetailsDrawer.vue";
import InfiniteLoading from 'vue-infinite-loading'
import AdvertisementHookRow from "./AdvertisementHookRow.vue";
import ManualHooksGeneratorModal from "./ManualHooksGeneratorModal.vue";
import firebase from "@/api/config/FirebaseConfig";
import ForeplayAPI from "@/api/foreplayServer";

// Icons
import ExportCSVIcon from "../../globals/Icons/ExportCSVIcon.vue";
import CopyIconStatic from "../../globals/Icons/CopyIconStatic.vue";
import UnpinIcon from "../../globals/Icons/SpyderV2Icons/UnpinIcon.vue";
import ChevronIcon from "../../globals/Icons/ChevronIcon.vue";
import AdDetailsV2Icon from "../../globals/Icons/AdDetailsAnimatedIcon.vue";

export default {
  name: "AdvertisementHooksList",
  components: {
    AdvertisementDetailsDrawer,
    AdvertisementHookRow,
    ManualHooksGeneratorModal,
    InfiniteLoading,
    ExportCSVIcon,
    CopyIconStatic,
    UnpinIcon,
    ChevronIcon,
    AdDetailsV2Icon,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedAdvertisement: {},
      showAdvertisementDetailsDrawer: false,
      pinnedAds: [],
      pinnedAdsLoaded: false,
      manualHooksGeneratorOpen: false,
      showOptions: true,
      hoveredHookPreview: null,
      hookPreviewPosition: { x: 0, y: 0 },
      infiniteId: +new Date(),
      attemptManualPreviewPlay: false,
      advertisements: [],
      loadingAdvertisements: false,
    };
  },
  computed: {
    ...mapState("AdvertisementsModule", ["adToOpen"]),
    ...mapGetters("AuthModule", [
      "getUserFreeTrialDate",
      "isFreeTier",
      "getTeam",
      "getUser",
    ]),

    filteredAdvertisements() {
      return this.advertisements.filter(
        (ad) =>
          ad.type === "video" &&
          ad.transcriptionStatus === "complete" &&
          !this.checkIfAdIsPinned(ad) &&
          ad.timestampedTranscription.length > 0
      );
    },
    trialExpired() {
      // check if a week has passed since trial and if they're free tier
      return (
        this.isFreeTier &&
        Date.now() > this.getUserFreeTrialDate + 6.048e8 &&
        !this.getTeam &&
        !this.discovery
      );
    },
  },
  watch: {
    adToOpen(newValue) {
      const newAd =
        this.advertisements.find((a) => a.ad_id === Number(newValue.ad_id)) ||
        {};

      if (Object.keys(newAd).length) {
        this.selectedAdvertisement = newAd;
        this.showAdvertisementDetailsDrawer = true;
      }
    },
    hoveredHookPreview(ad) {
      if (!ad) return;
      const hookElement = this.$refs[`hook-${ad.id}`][0];
      const hookRect = hookElement.$el.getBoundingClientRect();
      this.hookPreviewPosition = {
        x: hookRect.left + 50,
        y: hookRect.top + window.scrollY + hookRect.height / 2,
      };
    },
  },
  async mounted() {
    window.addEventListener("keydown", this.handleArrowNavigation);
    
    await this.fetchAdvertisements();
    await this.fetchPinnedAds()
    const adId = this.$route.query.ad;
    if (!adId) return;
    
    const newAd =
    this.advertisements.find((a) => a.ad_id === Number(adId)) || {};
    
    if (Object.keys(newAd).length) {
      this.selectedAdvertisement = newAd;
      this.showAdvertisementDetailsDrawer = true;
    }
  },
  methods: {
    ...mapMutations("AdvertisementsModule", ["SET_AD_TO_OPEN"]),
    async fetchPinnedAds() {
      try {
        const db = firebase.firestore();
        const user = this.getUser;
        if (!user) return;

        const adMap = {};
        this.advertisements.forEach((ad) => {
          adMap[ad.id] = ad;
        });

        db.collection("pinned-hooks")
          .where("userId", "==", user.user_id)
          .where("brandId", "==", this.advertisements[0].brandId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              console.log("data", data);
              if (data.adIds) {
                const ads = data.adIds
                  .map((adId) => adMap[adId])
                  .filter((ad) => ad);
                this.pinnedAds.push(...ads);
                this.pinnedAdsLoaded = true;
                console.log("pinnedAds", this.pinnedAds);
              }
            });
          });
      } catch (e) {
        console.log("error fetching", e);
      }
    },
    checkIfAdIsPinned(ad) {
      return this.pinnedAds.some((pinnedAd) => pinnedAd.id === ad.id);
    },
    async pinAd(ad) {
      try {
        const db = firebase.firestore();
        const user = this.getUser;
        if (!user) return;

        const docRef = db
          .collection("pinned-hooks")
          .where("userId", "==", user.user_id)
          .where("brandId", "==", ad.brandId);

        docRef.get().then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            doc.ref
              .update({
                adIds: firebase.firestore.FieldValue.arrayUnion(ad.id),
              })
              .then(() => {
                if (
                  this.pinnedAds &&
                  !this.pinnedAds.some((pinnedAd) => pinnedAd.id === ad.id)
                ) {
                  this.pinnedAds = [...this.pinnedAds, ad];
                }
              });
          } else {
            db.collection("pinned-hooks")
              .add({
                userId: user.user_id,
                brandId: ad.brandId,
                adIds: [ad.id],
              })
              .then(() => {
                if (
                  this.pinnedAds &&
                  !this.pinnedAds.some((pinnedAd) => pinnedAd.id === ad.id)
                ) {
                  this.pinnedAds = [...this.pinnedAds, ad];
                }
              });
          }
        });
      } catch (error) {
        console.log("Error pinning ad", error);
      }
    },
    async unpinAd(ad) {
      try {
        const db = firebase.firestore();
        const user = this.getUser;
        if (!user) return;

        const docRef = db
          .collection("pinned-hooks")
          .where("userId", "==", user.user_id)
          .where("brandId", "==", ad.brandId);

        docRef.get().then((querySnapshot) => {
          if (!querySnapshot.empty) {
            // Document exists, update it
            const doc = querySnapshot.docs[0];
            doc.ref
              .update({
                adIds: firebase.firestore.FieldValue.arrayRemove(ad.id),
              })
              .then(() => {
                if (
                  this.pinnedAds &&
                  this.pinnedAds.some((pinnedAd) => pinnedAd.id === ad.id)
                ) {
                  this.pinnedAds = this.pinnedAds.filter(
                    (pinnedAd) => pinnedAd.id !== ad.id
                  );
                }
              });
          }
        });
      } catch (error) {
        console.log("Error unpinning ad", error);
      }
    },
    clearPins() {
      if (!this.pinnedAds.length) return;
      this.pinnedAds.forEach((ad) => {
        this.unpinAd(ad);
      });
      this.pinnedAds = [];
    },
    copyAllPins() {
      if (!this.pinnedAds.length) return;
      const allPinnedHooks = this.pinnedAds
        .map((ad) => getHook(ad).trim())
        .join("\n");
      navigator.clipboard.writeText(allPinnedHooks);
    },
    toggleOpenManualHooksGenerator() {
      this.manualHooksGeneratorOpen = !this.manualHooksGeneratorOpen;
      // console.log('manualHooksGeneratorOpen', this.manualHooksGeneratorOpen)
    },
    exportCSV() {
      if (!this.pinnedAds.length) return;
      let csvContent = "Name,Link URL,Hook\n";
      let name;
      this.pinnedAds.forEach((ad) => {
        const row = `"${ad.name}","${ad.link_url}","${getHook(ad).trim()}"\n`;
        if (!name) name = ad.name;
        csvContent += row;
      });
      const sanitizedFileName = name.replace(/[^a-z0-9]/gi, "-");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `pinned-hooks-${sanitizedFileName}.csv`;
      link.click();
    },
    handleOpenAdvertisementDetailsDrawer(advertisement) {
      this.selectedAdvertisement = advertisement;
      this.showAdvertisementDetailsDrawer = true;
    },
    closeModal() {
      this.showAdvertisementDetailsDrawer = false;
      this.selectedAdvertisement = {};
      this.SET_AD_TO_OPEN({});
    },
    // Fetch Advertisements
    async fetchAdvertisements(load = true) {
      this.advertisements = [];
      this.loadingAdvertisements = load;
      const { 
        selectedPlatforms, selectedNiches, selectedLanguages, 
        selectedDateRange, sortOrder, textSearch, liveStatus
      } = this.filters

      const ranBetweenStart = +new Date(selectedDateRange?.start) || null
      const ranBetweenEnd = +new Date(selectedDateRange?.end) || null

      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getSpyderAds(
          null,
          {
            ranBetweenStart,
            ranBetweenEnd,
            textSearch,
            sort: sortOrder[0].value,
            transciptionOnly: true,
            orFilters: {
              niches: selectedNiches,
              publisher_platform: selectedPlatforms,
              liveStatus,
              languages: selectedLanguages,
              brands: [{ brandId: this.$route.params.id }],
            },
          }
        );

        this.lastDocId = nextPage;

        this.advertisements = results;
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingAdvertisements = false;
        this.infiniteId += 1;
      }

      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 600);
    },
    async getMoreAds($state) {
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete();
        }
        return;
      }
      const { 
        selectedPlatforms, selectedNiches, selectedLanguages, 
        selectedDateRange, sortOrder, textSearch, liveStatus
      } = this.filters

      const ranBetweenStart = +new Date(selectedDateRange?.start) || null
      const ranBetweenEnd = +new Date(selectedDateRange?.end) || null

      const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(
        this.lastDocId,
        {
          ranBetweenStart,
          ranBetweenEnd,
          textSearch,
          sort: sortOrder[0].value,
          transciptionOnly: true,
          orFilters: {
            niches: selectedNiches,
            publisher_platform: selectedPlatforms,
            liveStatus,
            brands: [{ brandId: this.$route.params.id }],
          },
        }
      );

      this.lastDocId = nextPage;

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete();
        }
        return;
      }

      this.advertisements = this.advertisements.concat(results);
      if (Object.keys($state).length) {
        $state.loaded();
      }
    },
  },
};
</script>

<style scoped>
.hooks-generator-button {
  display: flex;
    position: fixed;
    left: 293px;
    bottom: 20.5px;
    height: 44px;
    z-index: 1000;
    padding: 4px;
    color: white;
    border-radius: 10px;
    background: rgba(6, 7, 16, 0.92);
    backdrop-filter: blur(4px);
}
.inner-hooks-generator-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: rgba(6, 7, 16, 0.92); */
  padding: 6px 10px 6px 6px;
  cursor: pointer;
}
.hover-animation {
  animation: hoverUpDown 2s infinite;
}
@keyframes hoverUpDown {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1px);
  }
  75% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 200ms ease-in-out;
}
.v-enter-from,
.v-enter,
.v-leave-to {
  opacity: 0;
}
.v-enter-to,
.v-leave-from {
  opacity: 1;
}
</style>
