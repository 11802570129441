<template>
  <div class="flex w-full" @click="handleClick">
    <!-- CHECKBOX -->
    <div class="flex items-center justify-start w-12 h-8"
    @click.stop="$emit('toggleSelectedAd')">
      <input type="checkbox" :checked="checked"
      class="h-4 w-4 ml-2 rounded border-neutral-100 text-primary-blue-100 cursor-pointer focus:ring-transparent shadow-sm">
    </div>

    <!-- AD ROW CONTENT -->
    <div class="row-content-container w-full">
      <!-- AD INFORMATION -->
      <div class="flex items-center">
        <!-- Ad Asset -->
        <div class="relative mr-4" @mouseenter="thumbnailHovered = true" @mouseleave="thumbnailHovered = false"
        @click.stop.prevent="attemptManualPreviewPlay = !attemptManualPreviewPlay">
          <img v-if="adPreviewImageSrc" slot="reference" class="h-9 w-9 object-cover rounded-md" style="min-width: 36px" :src="adPreviewImageSrc">
          <video v-else-if="advertisement.video" slot="reference" class="h-9 w-9 object-cover rounded-md" style="min-width: 36px" :src="advertisement.video" />
          <transition>
            <div v-if="thumbnailHovered" class="absolute left-full top-1/2 transform -translate-y-1/2 pl-2" style="z-index: 50001"
            @mouseenter="thumbnailHovered = false">
              <BasePopupVideoPreview 
                v-if="advertisement.type === 'video' && advertisement.video"
                class="relative"
                :video-src="advertisement.video"
                :attempt-manual-play="attemptManualPreviewPlay"
              />
              <BasePopupThumbnailPreview 
                v-else-if="adPreviewImageSrc"
                class="relative"
                :thumbnail-src="adPreviewImageSrc"
              />
            </div>
          </transition>
        </div>
        <!-- Name label -->
        <div class="relative">
          <div class="ad-name-label-text text-text-muted text-sm font-medium pr-3"
          @mouseenter="adNameHovered = true" @mouseleave="adNameHovered = false">
            {{ advertisement.name }}
          </div>
          <!-- Label tooltip appears on hover -->
          <transition>
            <div v-if="adNameHovered" class="ad-name-tooltip pb-0.5 z-50" @mouseneter="adNameHovered = false">
              <div class="px-2 py-1 rounded-md shadow-lg bg-neutral-700 text-xs text-white whitespace-nowrap">
                {{ advertisement.name }}
              </div>
            </div>
          </transition>
        </div>
      </div>

      <!-- LIVE TIME -->
      <div class="flex items-center">
        <div v-if="!advertisement.publisher_platform?.includes('tiktok') && !advertisement.publisher_platform?.includes('linkedin') && !advertisement.organic && !isManualUpload"
        @mouseenter="liveTimeHovered = true" @mouseleave="liveTimeHovered = false" class="relative flex items-center">
          <!-- Platform Icon -->
          <img v-if="platformIconSrc" class="h-4 w-6 pr-2" :src="platformIconSrc" />
          <!-- Active indicator circle -->
          <div class="absolute w-2.5 h-2.5 rounded-full border-2 border-white"
          :style="{backgroundColor: advertisement?.live ? '#00AB75' : '#9CA3AF', bottom: '-1px', left: '11px'}" />
          <!-- Duration text -->
          <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap overflow-ellipsis">
            {{ formatLiveDateString() }}
          </BaseText>
          <transition>
            <div v-if="liveTimeHovered" class="live-time-tooltip pt-1.5 z-50">
              <div @mouseenter="liveTimeHovered = false" class="flex flex-col p-1 rounded-md bg-neutral-700 shadow-md">
                <!-- Display ad started date -->
                <div class="flex gap-2 items-center py-1.5 px-2">
                  <div class="w-1.5 h-1.5 rounded-full" :style="{backgroundColor: '#00A879'}" />
                  <div class="text-xs text-white whitespace-nowrap">
                    {{
                      advertisement?.startedRunning
                        ? formatLiveDate(advertisement?.startedRunning)
                        : "--"
                    }}
                  </div>
                </div>
                <!-- Display ad ended date (or if it's still running) -->
                <div class="flex gap-2 items-center py-1.5 px-2">
                  <div class="w-1.5 h-1.5 rounded-full bg-text-subdued" />
                  <div class="text-xs text-white whitespace-nowrap">
                    {{
                      advertisement?.live
                        ? "Still Running..."
                        : formatLiveDate(
                            advertisement?.lastChecked ||
                              advertisement?.last_checked
                          )
                    }}
                  </div>
                </div>
                <!-- Display total run duration -->
                <div class="flex gap-2 items-center py-1.5 px-2 mt-1 rounded bg-icon-muted">
                  <ClockIcon />
                  <div class="text-xs text-white whitespace-nowrap">
                    {{
                      `${formatRunningDuration(
                            advertisement?.startedRunning,
                            advertisement?.lastChecked || advertisement?.last_checked
                      )} ${
                        formatRunningDuration(
                              advertisement?.startedRunning,
                              advertisement?.lastChecked ||
                                advertisement?.last_checked
                        ) > 1
                          ? "Days"
                          : "Day"
                      }`
                    }}
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div v-else class="flex items-center">
          <svg v-if="isManualUpload" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1.5 -ml-0.5 text-icon-normal">
            <path d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <img v-else-if="platformIconSrc" class="h-4 w-6 pr-2" :src="platformIconSrc" />
          <div class="text-sm text-text-normal">
            Unavailable
          </div>
        </div>
      </div>

      <!-- BOARD SELECT -->
      <div class="rounded-lg border border-border-normal bg-white mr-3 transition-colors hover:border-border-hover">
        <SaveAdDropdown 
          :advertisement="advertisement"
          :isSavedAd="true"
          :isEditMode="true"
          noHoverOpener
          @dropdownOpened="boardDropdownOpen = true"
          @dropdownClosed="boardDropdownOpen = false"
          @updateBoards="handleUpdateAdBoardsLocal($event)"
        />
      </div>

      <!-- RATING SELECT -->
      <div class="mx-3">
        <RatingSelector :adRating="advertisement.rating || 0" @updateAdRating="handleUpdateAdRating" />
      </div>

      <!-- TAG SELECT -->
      <div class="mx-4 w-full">
        <TagsListDisplay :tags="advertisement.tags" :index="index" />
      </div>
    </div>

    <!-- OPTIONS DROPDOWN -->
    <div class="ml-auto mr-1.5">
      <BaseSelect text-trigger popper-center no-padding compact item-value="name" @click.native.stop @select="() => {}"
      :items="[
        {
          name: 'Share',
          action: shareAd,
          divider: true,
        },
        { name: 'Delete', action: handleDeleteAdvertisement },
      ]">
        <!-- Three Dots -->
        <div slot="reference" class="bg-transparent border border-transparent rounded-md transition ease-in-out
        hover:bg-white hover:border-border-normal">
          <KebabMenuIcon />
        </div>

        <!-- Dropdown Items -->
        <template #item="{ item }">
          <div class="flex max-w-max" style="width: max-content" @click="item.action()">
            <img v-if="item.name === 'Delete'" class="pr-2" src="../../assets/icons/trash.svg">
            <img v-if="item.name === 'Share'" class="pr-2" src="../../assets/icons/share.svg">
            <BaseText type="body" size="sm">
              {{ item.name }}
            </BaseText>
          </div>
        </template>
      </BaseSelect>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import SaveAdDropdown from './SaveAdDropdown/SaveAdDropdown.vue'
import RatingSelector from './DetailsDrawerComponents/RatingSelector.vue'
import TagsListDisplay from './DetailsDrawerComponents/TagsListDisplay.vue'

// Icons
import ClockIcon from '../globals/Icons/ClockIcon.vue'
import KebabMenuIcon from '../globals/Icons/KebabMenuIcon.vue'

export default {
  name: 'AdvertisementTableViewRow',
  components: {
    SaveAdDropdown,
    RatingSelector,
    TagsListDisplay,
    ClockIcon,
    KebabMenuIcon
  },
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    checked: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      selectedBoards: [],
      selectedTags: [],
      loadingDelete: false,
      thumbnailHovered: false,
      adNameHovered: false,
      liveTimeHovered: false,
      attemptManualPreviewPlay: false,
      boardDropdownOpen: false
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('TagsModule', ['getTags']),
    adPreviewImageSrc () {
      return this.advertisement.image 
        || this.advertisement.thumbnail 
        || (this.advertisement?.cards?.length && this.advertisement?.cards[0]?.image) 
        || (this.advertisement?.cards?.length && this.advertisement?.cards[0]?.thumbnail)
    },
    platformIconSrc () {
      if (this.advertisement.organic) {
      // Webpack finds and processes the image, and returns a URL to the processed image
        return require('../../assets/images/alternate-instagram-icon.svg');
      } else if (this.advertisement.publisher_platform) {
        const platform = this.advertisement.publisher_platform;
        if (platform.includes('facebook') || platform.includes('instagram') || platform.includes('messenger') || platform.includes('audience_network')) {
          return require('../../assets/images/facebook-small.png');
        } else if (platform.includes('tiktok')) {
          return require('../../assets/images/tiktok.svg');
        } else if (platform.includes('linkedin')) {
          return require('../../assets/images/linkedin.png');
        }
      }
      return null;
    },
    isManualUpload () {
      return this.advertisement?.name === 'Manual Upload'
    }
  },
  watch: {
    'advertisement.board_ids': function () {
      this.refreshBoards()
    },
    'advertisement.tags': async function () {
      const adTags = this.advertisement.tags || []
      this.selectedTags = await FirebaseAPI.Tags.getTagsArray(adTags)
    },
  },
  async mounted () {
    this.refreshBoards()

    const adTags = this.advertisement.tags || []
    this.selectedTags = await FirebaseAPI.Tags.getTagsArray(adTags)
  },
  methods: {
    handleClick () {
      if (!this.boardDropdownOpen) {
        this.$emit('adClicked')
      }
    },
    async shareAd () {
      await navigator.clipboard.writeText(
        process.env.VUE_APP_BASE_URL + '/share/ads/' + this.advertisement.id
      )

      this.$showAlert({
        message: 'Copied share link to clipboard!',
        type: 'success'
      })
    },
    async handleUpdateAdRating (rating) {
      try {
        await FirebaseAPI.Advertisements.update(this.advertisement.id, { rating: rating })
        const copyAd = { ...this.advertisement, rating: rating }
        this.$emit('updateAds', [copyAd])
        this.advertisement.rating = rating

        this.$showAlert({
          message: 'Ad rating updated',
          type: 'success'
        })
      } catch (error) {
        console.error("Couldn't update ad rating", error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
    },
    // Handle Delete Advertisement
    async handleDeleteAdvertisement () {
      const db = firebase.firestore()
      this.loadingDelete = true

      try {
        await db.collection('ads').doc(this.advertisement.id).delete()

        // TODO: Delete comments too

        // See if brand should be deleted
        if (this.advertisement.brandId) {
          await FirebaseAPI.Search.shouldBrandBeDeleted(this.advertisement)
        }

        // If manual upload, delete the saved asset
        if (this.advertisement.fileData) {
          // Delete the firebase asset
          const storageRef = firebase
            .app()
            .storage('gs://foreplay-manual-upload')
            .ref(this.advertisement.fileData.filePath)
          await storageRef.delete()

          // Update the users data limit
          const storageUsed =
            this.getUser.storageUsed -
            Math.ceil(this.advertisement.fileData.size / 1000)

          const user = firebase.auth().currentUser
          await FirebaseAPI.Users.update(user.uid, { storageUsed })

          // Set the local user
          this.SET_USER({ ...this.getUser, storageUsed })
        }

        this.$emit('removeAds', [this.advertisement])

        this.$showAlert({
          message: 'Ad deleted successfully!',
          type: 'success'
        })
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingDelete = false
      }
    },
    refreshBoards () {
      this.selectedBoards = this.getBoards.filter((board) =>
        this.advertisement.board_ids?.includes(board.id)
      )
    },
    formatRunningDuration (start, end) {
      let endTime = end._seconds * 1000

      if (isNaN(endTime)) {
        endTime = new Date().getTime()
      }
      // eslint-disable-next-line new-cap
      const startDate = new moment(start)
      // eslint-disable-next-line new-cap
      const endDate = new moment(endTime)

      return Math.ceil(moment.duration(endDate.diff(startDate)).as('days'))
    },
    formatLiveDate (date) {
      if (date.seconds) {
        const dateMS = date.seconds ? date.seconds * 1000 : date
        return moment(dateMS).format('MMM DD, YYYY')
      }

      const dateMS = date._seconds ? date._seconds * 1000 : date
      return moment(dateMS).format('MMM DD, YYYY')
    },
    handleUpdateAdTags: _.debounce(async function () {
      try {
        const payload = {
          tags: this.selectedTags.map((e) => e.id)
        }

        await FirebaseAPI.Advertisements.update(this.advertisement.id, payload)

        // Update Parent Ad
        const copyAd = { ...this.advertisement, tags: payload.tags }
        this.$emit('updateAds', [copyAd])

        this.$showAlert({
          message: 'Ad tags updated',
          type: 'success'
        })
        this.$emit('update')
      } catch (e) {
        this.$showAlert({
          message: e,
          type: 'error'
        })
      }
    }, 1000),
    handleUpdateAdBoardsLocal (boardIds) {
      const copyAd = { ...this.advertisement, board_ids: boardIds }
      this.$emit('updateAds', [copyAd])
    },
    formatLiveDateString () {
      const start = this.advertisement.startedRunning
      const end = this.advertisement.lastChecked || this.advertisement.last_checked

      let endTime = end._seconds * 1000

      if (isNaN(endTime)) {
        endTime = new Date().getTime()
      }
      // eslint-disable-next-line new-cap
      const startDate = new moment(start)
      // eslint-disable-next-line new-cap
      const endDate = new moment(endTime)

      const duration = Math.ceil(moment.duration(endDate.diff(startDate)).as('days'))

      return `${duration} Days`
    }
  }
}
</script>

<style scoped lang="css">
  .row-content-container {
    display: grid;
    grid-template-columns: 213px 115px 322px 130px 1fr;
    gap: 0.5rem;
    align-items: center;
  }
  .thumbnail-preview {
    position: absolute;
    width: 144px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    z-index: 99999;
  }
  .ad-name-label-text {
    /* Enables an ellipsis overflow after a max of 1 line break */
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ad-name-tooltip {
    position: absolute;
    bottom: 100%;
    left: -8px;
  }
  .live-time-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 130px;
  }
  .v-enter-active, .v-leave-active {
    transition: opacity 150ms ease-in-out;
  }
  .v-enter-from, .v-enter, .v-leave-to {
    opacity: 0;
  }
  .v-enter-to, .v-leave-from {
    opacity: 1;
  }
</style>