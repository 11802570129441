// Imports
import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'
import { createQuery } from '../../utils/filterUtils'

const getDiscoveryBrands = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/brands/discovery',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getBrandAnalytics = async (brandId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/brands/analytics/${brandId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getCreativeTests = async (nextId, brandId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/brands/creative-tests/${brandId}`,
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getPinnedCreativeTests = async (dates, brandId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const payload = {
    dates: dates
  }

  const { data } = await AxiosConfig.post(
     `/brands/pinned-creative-tests/${brandId}`,
     payload,
     {
       headers: {
         Authorization: `Bearer ${token}`
       }
     }
  )

  return data
}

const generateHooksFromPinned = async (product, hooks, promptType) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const payload = {
    product: product,
    hooks: hooks,
    promptType: promptType
  }
  const { data } = await AxiosConfig.post(
    '/brands/manual-hook-generator',
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

export default {
  getCreativeTests,
  getDiscoveryBrands,
  getBrandAnalytics,
  getPinnedCreativeTests,
  generateHooksFromPinned
}
