<template>
  <!-- Discover View -->
  <MainLayout
    v-scroll="onScroll"
    scroll-toolbar
    :bg-color="viewToggleValue === 'Experts' ? 'white' : ''"
    class="relative"
  >
    <template #toolbar>
      <div
        class="flex items-center justify-between px-5 py-3.5"
        :class="viewToggleValue === 'Experts' ? 'border-b border-border-normal' : ''"
      >
        <div class="flex items-center">
          <img
            src="../../assets/images/discovery-new.png"
            class="w-10 h-10 self-start mt-0.5"
          >
          <div class="ml-3">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Discovery
            </BaseText>

            <BaseText
              type="body"
              size="sm"
              class="text-text-normal w-52 overflow-visible whitespace-nowrap"
            >
              Browse 5M+ ads saved by users
            </BaseText>
          </div>
        </div>

        <BaseViewToggle
          :views="['Community', 'Brands', 'Experts']"
          :value="viewToggleValue"
          icons
          @input="(value) => (viewToggleValue = value)"
        >
          <svg
            slot="icon1"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="transition duration-300 mr-1"
            :class="viewToggleValue === 'Community' ? 'text-black' : 'text-neutral-500'"
          >
            <path
              d="M14.7917 16.0417H16.9551C17.9242 16.0417 18.7103 15.2074 18.4449 14.2754C17.834 12.1308 16.3908 10.625 14.1667 10.625C13.795 10.625 13.4451 10.6671 13.1173 10.7471M9.37499 5.83333C9.37499 7.3291 8.16243 8.54167 6.66666 8.54167C5.17089 8.54167 3.95833 7.3291 3.95833 5.83333C3.95833 4.33756 5.17089 3.125 6.66666 3.125C8.16243 3.125 9.37499 4.33756 9.37499 5.83333ZM16.4583 6.25C16.4583 7.51565 15.4323 8.54167 14.1667 8.54167C12.901 8.54167 11.875 7.51565 11.875 6.25C11.875 4.98435 12.901 3.95833 14.1667 3.95833C15.4323 3.95833 16.4583 4.98435 16.4583 6.25ZM1.73438 15.105C2.38323 12.4889 4.04904 10.625 6.66666 10.625C9.28428 10.625 10.9501 12.4889 11.5989 15.105C11.832 16.0449 11.0504 16.875 10.082 16.875H3.25131C2.28296 16.875 1.50128 16.0449 1.73438 15.105Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            slot="icon2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="transition duration-300 mr-1"
            :class="viewToggleValue === 'Brands' ? 'text-black' : 'text-neutral-500'"
          >
            <path
              d="M3.95841 16.875V4.79167C3.95841 3.87119 4.70461 3.125 5.62508 3.125H14.3751C15.2956 3.125 16.0417 3.87119 16.0417 4.79167V16.875M3.95841 16.875H16.0417M3.95841 16.875H2.29175M16.0417 16.875H17.7084M7.29175 6.45833H8.54175M11.4584 6.45833H12.7084M7.29175 9.79167H8.54175M11.4584 9.79167H12.7084M7.29175 13.125H8.54175M11.4584 13.125H12.7084"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            slot="icon3"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="transition duration-300 mr-1"
            :class="viewToggleValue === 'Experts' ? 'text-black' : 'text-neutral-500'"
          >
            <path
              d="M10.3105 10.1883L11.0188 13.1199C11.1059 13.4801 11.5847 13.556 11.7788 13.2402L13.2251 10.8878C13.3069 10.7547 13.4564 10.6786 13.6121 10.6906L16.3654 10.9033C16.7349 10.9318 16.955 10.4999 16.7147 10.2177L14.9243 8.11524C14.823 7.99628 14.7968 7.83065 14.8564 7.68621L15.9094 5.13342C16.0508 4.7908 15.708 4.44802 15.3654 4.58935L12.8126 5.64239C12.6681 5.70197 12.5025 5.67574 12.3835 5.57444L10.2811 3.78408C9.99893 3.54379 9.56699 3.76387 9.59553 4.13339L9.80818 6.88665C9.82021 7.04243 9.74408 7.19185 9.61097 7.27369L7.25855 8.71998C6.94282 8.91409 7.01866 9.39289 7.37892 9.47994L10.3105 10.1883ZM10.3105 10.1883L3.625 16.8737"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </BaseViewToggle>

        <div class="flex items-center">
          <BaseButton
            class="mr-3"
            @click="showTutorialModal = true"
          >
            <div class="flex">
              <img
                src="../../assets/icons/tutorial.svg"
                alt=""
                class="mr-1.5"
              >
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Tutorial
              </BaseText>
            </div>
          </BaseButton>
          <BaseSelect
            no-shadow
            no-padding
            white-background
            text-trigger
            close-dropdown
            item-value="name"
            popper-center
            :items="[
              {
                name: 'Save New Ad',
                icon: 'plus',
                action: openAdModal,
              },
              {
                name: 'Manual Upload',
                icon: 'upload',
                action: openManualUpload,
              },
              {
                name: 'Create Board',
                icon: 'board',
                action: () => showManageBoardModal = true,
              },
              {
                name: 'Create Folder',
                icon: 'folder',
                action: () => showFolderModal = true,
              },
              {
                name: 'Create Brief',
                icon: 'brief',
                action: () => $router.push({ name: 'BriefsView' }).catch(() => {}),
              },
              {
                name: 'Add Spyder',
                icon: 'spyder',
                action: () => {$router.push({ name: 'SpyderBrandsView' })}
              },
            ]"
            @select="() => {}"
            @input="() => {}"
          >
            <div
              slot="reference"
            >
              <BaseButton
                primary
                class
                @click="showCreateBriefModal = true"
              >
                <div
                  class="flex items-center justify-between w-full h-full"
                >
                  <img
                    src="../../assets/icons/library-create-icon.svg"
                    class="w-5 h-5 mr-1.5 -ml-1"
                  >
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    Create New
                  </BaseText>
                </div>
              </BaseButton>
            </div>
            <template #item="{ item }">
              <div
                class="flex items-center w-full h-full"
              >
                <img
                  v-if="item.icon === 'plus'"
                  src="../../assets/icons/plus-icon.svg"
                  :alt="item.name"
                  class="w-6 h-6 mr-1.5 -ml-px"
                >
                <svg
                  v-if="item.icon === 'upload'"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  class="w-5 h-5 mr-1.5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875M16.875 10.625V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V10.625"
                    stroke="#5E6678"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <img
                  v-if="item.icon === 'board'"
                  src="../../assets/icons/create-board-icon.svg"
                  :alt="item.name"
                  class="w-5 h-5 mr-1.5"
                >
                <img
                  v-if="item.icon === 'folder'"
                  src="../../assets/icons/create-folder-icon.svg"
                  :alt="item.name"
                  class="w-5 h-5 mr-1.5"
                >
                <img
                  v-if="item.icon === 'brief'"
                  src="../../assets/icons/document-gray.svg"
                  :alt="item.name"
                  class="w-5 h-5 mr-1.5"
                >
                <svg
                  v-if="item.icon === 'spyder'"
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 mr-1.5 text-icon-normal"
                >
                  <path
                    d="M7.4774 14.6967V19.0996C7.4774 19.0996 3.8498 16.1644 2.20665 13.6058C0.192936 10.4846 0.222191 8.67941 0.222191 8.67941L2.57234 7.78902C2.57234 7.86238 2.57234 7.94068 2.57234 8.01894C2.61267 9.87949 3.33879 11.6591 4.61041 13.0138C5.55634 14.0607 6.66315 14.6967 7.4774 14.6967Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.7374 8.67912C14.7374 8.67912 14.7666 10.4844 12.7529 13.5713C11.1098 16.1054 7.47726 19.0651 7.47726 19.0651V14.6622C8.34519 14.6622 9.42758 14.0507 10.3589 13.0429C11.6386 11.6804 12.3699 9.89046 12.4116 8.0187C12.4116 7.94044 12.4116 7.86703 12.4116 7.79367L14.7374 8.67912Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.47738 0.900574V5.93949L2.57231 7.7887L0.222168 8.67905C0.222168 8.67905 2.07498 0.900574 7.47738 0.900574Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.47738 0.900574C12.8115 0.900574 14.7374 8.67912 14.7374 8.67912L12.4116 7.79367L7.47738 5.93949V0.900574Z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.10724 7.77576C5.1543 7.65645 5.32316 7.65645 5.37022 7.77576L5.61345 8.3925C5.64218 8.46535 5.69985 8.52302 5.77271 8.55175L6.38944 8.79499C6.50875 8.84205 6.50875 9.0109 6.38944 9.05796L5.77271 9.3012C5.69985 9.32993 5.64218 9.3876 5.61345 9.46045L5.37022 10.0772C5.32316 10.1965 5.1543 10.1965 5.10724 10.0772L4.86401 9.46045C4.83528 9.3876 4.77761 9.32993 4.70475 9.3012L4.08802 9.05796C3.96871 9.0109 3.96871 8.84205 4.08802 8.79499L4.70475 8.55175C4.77761 8.52302 4.83528 8.46535 4.86401 8.3925L5.10724 7.77576Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.5739 8.81699L10.4695 8.7795C10.1656 8.67032 9.84509 8.6145 9.52216 8.6145C9.19923 8.6145 8.87874 8.67032 8.57483 8.7795L8.47046 8.81699C8.36141 8.85617 8.36141 8.99674 8.47046 9.03591L8.57483 9.07341C8.87874 9.18258 9.19923 9.2384 9.52216 9.2384C9.84509 9.2384 10.1656 9.18258 10.4695 9.07341L10.5739 9.03591C10.6829 8.99674 10.6829 8.85617 10.5739 8.81699Z"
                    fill="currentColor"
                  />
                </svg>
                <div
                  class="flex items-center cursor-pointer"
                  @click="item.action"
                >
                  {{ item.name }}
                </div>
              </div>
            </template>
          </BaseSelect>
        </div>
      </div>
    </template>

    <!-- Filter Bar -->
    <template
      v-if="selectedTab !== 'experts'"
      #filter
    >
      <div
        ref="filterBar"
        class="border border-border-normal bg-white bg-opacity-90"
        style="backdrop-filter: blur(6px);"
      >
        <FilterTopBar
          v-if="isAdTab"
          :keyword-search-opened="openKeywordSearch"
          :no-search="!isAdTab"
          :alphabetical="!isAdTab"
          :selected-formats.sync="selectedFormats"
          :selected-platforms.sync="selectedPlatforms"
          :selected-niches.sync="selectedNiches"
          :selected-date-range.sync="selectedDateRange"
          :sort-order.sync="sortOrder"
          :text-search.sync="textSearch"
          :live-status.sync="liveStatus"
          @change="fetchAdvertisements"
          @resetKeywordSearch="openKeywordSearch = false"
        />
        <FilterTopBar
          v-else
          alphabetical
          discovery-brands
          :selected-niches.sync="selectedNiches"
          :favorite-filter.sync="favoriteFilter"
          :text-search.sync="textSearch"
          :sort-order.sync="sortOrder"
          @change="fetchBrands"
        />
      </div>
    </template>

    <template #content>
      <div v-if="selectedTab === 'ads'">
        <!-- Loading State -->
        <BaseLoadingLogo
          v-if="loadingAdvertisements || loadingShuffledAds"
          class="mt-24"
        />

        <!-- Empty State With Filters -->
        <div
          v-else-if="!advertisements.length"
          class="flex flex-col items-center mt-20"
        >
          <img
            src="../../assets/images/empty-with-filters.gif"
            class="border border-gray-400 rounded mx-auto object-cover"
            style="width: 400px; height: 300px"
          >

          <div class="text-lg font-bold my-4">
            Too many filters
          </div>

          <div class="text-center text-gray-700">
            No ads found that meet your current filters
          </div>

          <div class="flex mt-5">
            <BaseButton
              primary
              @click="clearFilters"
            >
              Clear filters
            </BaseButton>
          </div>
        </div>

        <!-- Advertisements List -->
        <AdvertisementList
          v-else
          :discovery="true"
          :advertisements="advertisements"
          :loaded="!loadingAdvertisements"
          @updateAds="updateAdsLocal"
          @getMoreAds="isShuffled ? () => getMoreShuffledAds({}) : () => getMoreAds({})"
          @reload="fetchAdvertisements(false)"
        />

        <infinite-loading
          v-if="advertisements.length"
          :identifier="infiniteId"
          @infinite="handleInfiniteLoading"
        >
          <div slot="spinner">
            <BaseLoadingLogo :margin="2" />
          </div>
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
        <transition name="shuffleFade">
          <ShuffleButton
            :can-be-shuffled="isAnySelected"
            @shuffle="fetchShuffledAdvertisements(false)"
            @update:selectedLanguages="updateSelectedLanguages"
            @open-keyword-search="toggleKeywordSearchMethod"
          />
        </transition>
      </div>

      <DiscoveryExperts v-else-if="$route.name === 'DiscoveryExpertsView'" />

      <DiscoveryBrands
        v-else
        :text-search="textSearch"
        :update-query="shouldUpdateQuery"
        :favorite-filter="favoriteFilter"
        :selected-niches="selectedNiches"
        :sort-order="sortOrder"
      />

      <!-- Create Advertisement Modal -->
      <CreateAdvertisementModal
        v-if="showAdvertisementModal"
        :starting-tab="manualUpload ? 'manual' : 'extension'"
        @close="showAdvertisementModal = false"
        @save="fetchAdvertisements(false)"
      />

      <!-- Manage Board Modal -->
      <ManageBoardModal
        v-if="showManageBoardModal"
        :board="{}"
        @close="showManageBoardModal = false"
      />

      <TutorialModal
        v-if="showTutorialModal"
        @close="showTutorialModal = false"
      />

      <FolderModal
        v-if="showFolderModal"
        @close="showFolderModal = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters } from 'vuex'

import ForeplayAPI from '@/api/foreplayServer'
import firebase from '../../api/config/FirebaseConfig'
import ManageBoardModal from '../../components/boards/ManageBoardModal'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import CreateAdvertisementModal from '../../components/advertisements/AdvertisementCreateModal'
import MainLayout from '../../layouts/MainLayout'
import DiscoveryBrands from '../../components/discovery/DiscoveryBrands.vue'
import DiscoveryExperts from '../../components/discovery/DiscoveryExperts.vue'
import TutorialModal from '../../components/advertisements/TutorialModal'
import FilterTopBar from '../../components/search/FilterTopBar.vue'
import FolderModal from '../../components/sidebar/FolderModal.vue'
import ShuffleButton from './ShuffleButton.vue'

export default {
  name: 'DiscoveryView',
  components: {
    ShuffleButton,
    AdvertisementList,
    CreateAdvertisementModal,
    DiscoveryBrands,
    DiscoveryExperts,
    FilterTopBar,
    FolderModal,
    InfiniteLoading,
    TutorialModal,
    MainLayout,
    ManageBoardModal
  },
  data () {
    return {
      niches: [],
      advertisements: [],
      loadingAdvertisements: false,
      loadingShuffledAds: false,
      showManageBoardModal: false,
      showAdvertisementModal: false,
      showFolderModal: false,
      showTutorialModal: false,
      manualUpload: false,
      filtersApplied: false,
      selectedFormats: [],
      selectedNiches: [],
      selectedLanguages: [],
      openKeywordSearch: false,
      selectedDateRange: {
        start: null,
        end: null
      },
      liveStatus: [],
      sortOrder: [],
      selectedPlatforms: [],
      lastDocId: null,
      infiniteId: +new Date(),
      selectedTab: 'ads',
      docOrder: 'desc',
      viewToggleValue: 'Community',

      // Search Specific
      searchMode: false,
      searchPage: 0,
      searchQuery: '',
      textSearch: '',
      infiniteLoaderState: null,

      // Brands Section
      favoriteFilter: false,
      shouldUpdateQuery: new Date().toString(),
      isShuffled: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('RouterModule', ['getLastRoute']),
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('AuthModule', [
      'getTeam',
      'isFreeTier',
      'getUserFreeTrialDate',
      'getUser'
    ]),
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('TagsModule', ['getTags']),
    isAdTab () {
      return this.selectedTab === 'ads'
    },
    isAnySelected () {
      return (
        this.selectedFormats.length > 0 ||
        this.selectedNiches.length > 0 ||
        this.selectedLanguages.length > 0 ||
        this.selectedPlatforms.length > 0 ||
        this.textSearch !== ''
      )
    }
  },
  watch: {
    viewToggleValue (newVal, oldVal) {
      if (newVal === 'Community') {
        this.$router.push({ name: 'DiscoveryView' }).catch(() => {})
      } else if (newVal === 'Brands') {
        this.$router.push({ name: 'DiscoveryBrandsView' }).catch(() => {})
      } else if (newVal === 'Experts') {
        this.$router.push({ name: 'DiscoveryExpertsView' }).catch(() => {})
      }
    },
    selectedLanguages (newVal, oldVal) {
      this.fetchAdvertisements()
    }
  },
  async mounted () {
    if (this.$route.name === 'DiscoveryBrandsView') {
      this.viewToggleValue = 'Brands'
    } else if (this.$route.name === 'DiscoveryExpertsView') {
      this.viewToggleValue = 'Experts'
    } else {
      this.viewToggleValue = 'Community'
    }
    // Set initial top bar styles
    if (this.$refs.filterBar) {
      this.$refs.filterBar.style.position = 'sticky'
      this.$refs.filterBar.style.top = '16px'
      this.$refs.filterBar.style.zIndex = '50000'
    }
    if (this.$route.name === 'DiscoveryBrandsView') {
      this.selectedTab = 'brands'
      this.sortOrder = [{ name: 'Ads Saved', value: 'saved' }]
    } else {
      this.sortOrder = [{ name: 'Newest', value: 'desc' }]
    }

    if (this.$route.name === 'DiscoveryBrandsView') {
      this.selectedTab = 'brands'
      this.niches = await this.getNiches()
    } else if (this.$route.name === 'DiscoveryExpertsView') {
      this.selectedTab = 'experts'
    } else {
      await this.fetchAdvertisements()
      this.niches = await this.getNiches()
      await this.getUsersTags()
      await this.fetchTags()
    }
    this.selectedLanguages = this.getUser.defaultLanguages || []
    // console.log('selectedLanguages in DiscoveryView on mount:', this.selectedLanguages)
  },
  methods: {
    toggleKeywordSearchMethod () {
      console.log('openKeywordSearch triggered:', this.openKeywordSearch)
      this.openKeywordSearch = !this.openKeywordSearch
    },
    ...mapActions('BoardsModule', ['fetchBoards']),
    ...mapActions('TagsModule', ['fetchTags']),
    fetchBrands () {
      this.shouldUpdateQuery = new Date().toString()
    },
    updateSelectedLanguages (newSelectedLanguages) {
      this.selectedLanguages = newSelectedLanguages
      console.log('selectedLanguages in DiscoveryView:', this.selectedLanguages)
    },
    openManualUpload () {
      this.manualUpload = true
      this.showAdvertisementModal = true
    },
    openAdModal () {
      this.manualUpload = false
      this.showAdvertisementModal = true
    },
    startPosition (position) {
      this.$refs.bottomShadow.classList.add(`start${position}`)
    },
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.marginLeft = `${marginValue}px`
        this.$refs.filterBar.style.marginRight = `${marginValue}px`
        this.$refs.filterBar.style.marginTop = `${marginValue}px`
        this.$refs.filterBar.style.borderRadius = `${rounding}px`
      }
      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop && this.$refs.filterBar) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        15
      )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        7
      )}px ${shadowSection(
        10
      )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        7
      )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        3
      )}px ${shadowSection(5)}px`
      // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        if (this.$refs.filterBar) {
          this.$refs.filterBar.style.boxShadow = null
        }
      }
    },
    moveShadowRight () {
      this.$refs.bottomShadow.classList.add('moveRight')

      this.$refs.bottomShadow.classList.remove('moveLeft')
      this.$refs.bottomShadow.classList.remove('moveCenter')
    },
    moveShadowCenter () {
      this.$refs.bottomShadow.classList.add('moveCenter')

      this.$refs.bottomShadow.classList.remove('moveLeft')
      this.$refs.bottomShadow.classList.remove('moveRight')
    },
    moveShadowLeft () {
      this.$refs.bottomShadow.classList.add('moveLeft')

      this.$refs.bottomShadow.classList.remove('moveRight')
      this.$refs.bottomShadow.classList.remove('moveCenter')
    },
    updateFilter (filterName, items) {
      this[filterName] = items
    },
    toggleFavoriteFilter () {
      this.favoriteFilter = !this.favoriteFilter
    },
    async getUsersTags () {
      let userTags

      if (this.getTeam) {
        userTags = await FirebaseAPI.Tags.getAllByTeam(this.getTeam.id)
      } else {
        userTags = await FirebaseAPI.Tags.getAllByUser()
      }

      return userTags
    },
    async getNiches () {
      // Setup
      const db = firebase.firestore()

      const snapshot = await db.collection('niches').get()

      const newDocs = snapshot.docs?.map((doc) => {
        const docData = {
          ...(doc.data() || [])
        }

        return { name: docData.niche, icon: docData.emoji }
      })

      const sortedDocs = newDocs.sort((a, b) => (a.name > b.name ? 1 : -1))
      const otherIndex = sortedDocs.findIndex((d) => d.name === 'Other')
      const otherNiche = sortedDocs.splice(otherIndex, 1)

      sortedDocs.push(otherNiche[0])

      return sortedDocs
    },
    async firebaseQuery () {
      // Setup
      const db = firebase.firestore()

      let query
      if (
        this.selectedPlatforms.length ||
        this.selectedNiches.length ||
        this.selectedFormats
      ) {
        // Create where conditions to be spread into the query
        //   The filter map fields will be used here
        const whereConditions = [
          ...this.selectedPlatforms.map((n) => [
            `filters.platforms.${n.name.toLowerCase()}`,
            '==',
            true
          ])
        ]

        if (this.selectedNiches.length) {
          const nichesToFilterFor = this.selectedNiches.map((n) => n.name)
          whereConditions.push([
            'filters.niches',
            'array-contains-any',
            nichesToFilterFor
          ])
        }

        if (this.selectedFormats.length) {
          const formatToFilterFor = this.selectedFormats
            .map((n) => n.name)[0]
            .toLowerCase()
          whereConditions.push(['type', '==', formatToFilterFor])
        }

        query = await db.collection('unique-ads')

        // Add all the conditions for the query
        whereConditions.forEach((condition) => {
          query = query.where(...condition)
        })

        query = query.orderBy('createdAt', this.docOrder)
      } else {
        // Just get the ads in order
        query = await db
          .collection('unique-ads')
          .orderBy('createdAt', this.docOrder)
      }

      return query
    },
    handleInfiniteLoading ($state) {
      if (this.isShuffled) {
        this.getMoreShuffledAds($state)
      } else {
        this.getMoreAds($state)
      }
    },
    async getMoreAds ($state) {
      console.log('this.isShuffled', this.isShuffled)
      console.log('fetching more regular ads')
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(this.lastDocId, {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages
        }
      })

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    async getMoreShuffledAds ($state) {
      console.log('this.isShuffled', this.isShuffled)
      console.log('getting more shuffled ads')
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const { results, nextPage } = await ForeplayAPI.Ads.getShuffledDiscoveryAds(this.lastDocId, {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages
        }
      })

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    // Handle Clear Filters
    async clearFilters () {
      this.selectedNiches = []
      this.selectedFormats = []
      this.selectedPlatforms = []

      await this.fetchAdvertisements()

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    async fetchShuffledAdvertisements (load = true) {
      this.loadingShuffledAds = true
      console.log('fetching ads via fetchShuffledAdvertisements')
      this.advertisements = []
      this.loadingAdvertisements = load

      const savedBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange?.end) || null
      try {
        await this.fetchBoards()

        const { results, nextPage } = await ForeplayAPI.Ads.getShuffledDiscoveryAds(null, {
          savedBetweenStart,
          savedBetweenEnd,
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages
          }
        })

        console.log('nextPage', nextPage)
        this.lastDocId = nextPage

        this.advertisements = results
        this.loadingShuffledAds = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1
      }
      this.isShuffled = true
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    // Fetch Advertisements
    async fetchAdvertisements (load = true) {
      console.log('fetching ads via fetchAdvertisements')
      this.advertisements = []
      this.loadingAdvertisements = load

      const savedBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange?.end) || null
      try {
        await this.fetchBoards()

        const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(null, {
          savedBetweenStart,
          savedBetweenEnd,
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages
          }
        })

        console.log('nextPage', nextPage)
        this.lastDocId = nextPage

        this.advertisements = results
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1
      }
      this.isShuffled = false

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    updateAdsLocal (adsToUpdate) {
      console.log('updating ads', adsToUpdate)
      const duplicateAds = [...this.advertisements]

      adsToUpdate.forEach((ad) => {
        const index = duplicateAds.findIndex((a) => a.id === ad.id)

        if (index !== -1) {
          duplicateAds[index] = ad
        }
      })

      this.advertisements = duplicateAds
    }
  },
  metaInfo: {
    title: 'Foreplay.co | Discovery'
  }
}
</script>

<style scoped>
.shadow-elem {
  display: flex;
  justify-content: center;
  width: 34%;
}

.tool-el {
  /* Right */
  --right-width: 25%;
  --right-translate: 72%;

  /* Center */
  --center-width: 25%;
  --center-translate: 39%;

  /* Left */
  --left-width: 34%;
  --left-translate: 0%;
}

/* Right */
.moveRight {
  animation: moveRightAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveRight div {
  animation: rightWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startRight {
  transform: translateX(var(--right-translate));
}

.startRight div {
  width: var(--right-width)
}

/* Center */
.moveCenter {
  animation: moveCenterAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveCenter div {
  animation: centerWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startCenter {
  transform: translateX(var(--center-translate));
}

.startCenter div {
  width: var(--center-width)
}

/* Left */
.moveLeft {
  animation: moveLeftAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveLeft div {
  animation: leftWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startLeft {
  transform: translateX(var(--left-translate));
}

.startLeft div {
  width: var(--left-width)
}
.shuffleFade-enter-active, .shuffleFade-leave-active {
  transition: opacity 0.3s;
}
.shuffleFade-enter, .shuffleFade-leave-to {
  opacity: 0;
}

/* Rigth Animations */
@keyframes moveRightAnimation {
  to {
    transform: translateX(var(--right-translate));
  }
}

@keyframes rightWidthAnimation {
  to {
    width: var(--right-width)
  }
}

/* Center Animations */
@keyframes moveCenterAnimation {
  to {
    transform: translateX(var(--center-translate));
  }
}

@keyframes centerWidthAnimation {
  to {
    width: var(--center-width)
  }
}

/* Left Animations */
@keyframes moveLeftAnimation {
  to {
    transform: translateX(var(--left-translate));
  }
}

@keyframes leftWidthAnimation {
  to {
    width: var(--left-width)
  }
}
</style>
